// src/components/ThankYouPage.js

import React, { useContext, useEffect } from 'react';
import { Container, Typography, Box, Button, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { StepperContext } from '../context/StepperContext';

function ThankYouPage() {
    const navigate = useNavigate();
    const { setActiveStep } = useContext(StepperContext);

    useEffect(() => {
        setActiveStep(3); // Paso 3: Confirmación
    }, [setActiveStep]);

    return (
        <Container maxWidth="md" sx={{ mt: 8, textAlign: 'center' }}>
            <Fade in timeout={1000}>
                <Box>
                    <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80 }} />
                    <Typography variant="h4" gutterBottom sx={{ color: 'green', mt: 2 }}>
                        ¡Gracias por tu Regalo!
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 4, color: '#555' }}>
                        Tu generosidad es muy apreciada. Los novios han sido notificados de tu regalo.
                    </Typography>
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" onClick={() => navigate('/')} sx={{ mt: 2 }}>
                            Volver al Inicio
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Container>
    );
}

export default ThankYouPage;
