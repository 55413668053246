// src/components/Home/HowItWorks.js
import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const steps = [
    {
        title: 'Crear tu Lista',
        description: 'Regístrate fácilmente, accede a tu cuenta y selecciona "Crear Lista". Añade los regalos que deseas recibir y personaliza tu lista según tus preferencias.',
        icon: <CreateIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Compartir con Invitados',
        description: 'Envía el enlace de tu lista a tus invitados a través de correo electrónico, redes sociales o directamente desde la plataforma de manera rápida y sencilla.',
        icon: <ShareIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Gestionar Contribuciones',
        description: 'Controla las contribuciones recibidas, agradece a tus invitados y actualiza tu lista en tiempo real con facilidad y eficiencia.',
        icon: <ManageAccountsIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
];

function HowItWorks() {
    return (
        <Container sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                ¿Cómo Funciona?
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ color: '#555', mb: 6 }}>
                Sigue estos sencillos pasos para comenzar y aprovechar al máximo nuestras ventajas.
            </Typography>
            <Grid container spacing={4}>
                {steps.map((step, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Fade direction="up" triggerOnce>
                            <Box sx={{ textAlign: 'center', padding: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                    {step.icon}
                                </Box>
                                <Typography variant="h5" gutterBottom sx={{ color: '#FF8E53' }}>
                                    {step.title}
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#777' }}>
                                    {step.description}
                                </Typography>
                            </Box>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default HowItWorks;
