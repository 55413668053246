// src/components/Home/WhyChooseUs.js
import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import PercentIcon from '@mui/icons-material/Percent';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const reasons = [
    {
        title: 'Tarifas Reducidas',
        description: 'Solo cobramos un 5% por los regalos, la mitad que otras plataformas, permitiéndote ahorrar más para tu gran día.',
        icon: <PercentIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Experiencia Intuitiva',
        description: 'Nuestra interfaz es fácil de usar tanto para las parejas como para los invitados, garantizando una experiencia sin complicaciones.',
        icon: <ThumbUpIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Reconocidos por la Calidad',
        description: 'Somos líderes en satisfacción del cliente, ofreciendo un servicio confiable y de alta calidad que supera las expectativas.',
        icon: <EmojiEventsIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
];

function WhyChooseUs() {
    return (
        <Container sx={{ py: 8 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                ¿Por Qué Elegirnos?
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ color: '#555', mb: 6 }}>
                Descubre las razones que nos hacen la mejor opción para organizar tus regalos de boda.
            </Typography>
            <Grid container spacing={4}>
                {reasons.map((reason, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Fade triggerOnce>
                            <Box sx={{ textAlign: 'center', padding: 3, boxShadow: 3, borderRadius: 2, backgroundColor: '#fff' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                    {reason.icon}
                                </Box>
                                <Typography variant="h5" gutterBottom sx={{ color: '#FF8E53' }}>
                                    {reason.title}
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#777' }}>
                                    {reason.description}
                                </Typography>
                            </Box>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default WhyChooseUs;
