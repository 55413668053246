// src/components/Login.js
import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { Container, Button, Typography, Alert, Box, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';

function Login() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleGoogleLogin = () => {
        setLoading(true);
        window.location.href = '/api/login/google';  // Cambio aquí
    };

    return (
        <Container maxWidth="sm">
            <Box mt={8} display="flex" flexDirection="column" alignItems="center">
                <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#F4E1D2' }}>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        Accede a tu Cuenta
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        Inicia sesión con tu cuenta de Google para gestionar tu lista de regalos.
                    </Typography>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <Box sx={{ mt: 4, width: '100%' }}>
                        {loading ? (
                            <CircularProgress color="primary" />
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                startIcon={<GoogleIcon />}
                                onClick={handleGoogleLogin}
                                sx={{
                                    py: 1.5,
                                    backgroundColor: '#DB4437',
                                    color: '#FFFFFF',
                                    '&:hover': { backgroundColor: '#C33D2E' },
                                }}
                            >
                                Iniciar Sesión con Google
                            </Button>
                        )}
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

export default Login;