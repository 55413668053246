// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, CircularProgress, Box } from '@mui/material';
import theme from './theme';
import Navbar from './components/Navbar';
import Home from './components/Home/Home';
import GiftList from './components/GiftList';
import Checkout from './components/Checkout';
import CompleteProfile from './components/CompleteProfile';
import Login from './components/Login';
import Admin from './components/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './context/UserContext';
import Invitados from './components/Invitados';
import ThankYouPage from './components/ThankYouPage';
import StepperLayout from './components/StepperLayout';
import { StepperProvider } from './context/StepperContext';
import Profile from './components/Profile'; // Nuevo componente de perfil
// import Register from './components/Register'; // Componente de registro rápido
import FAQ from './components/Home/FAQ'; // Asegúrate de tener este componente
import Contact from './components/Contact'; // Componente de contacto
import Terms from './components/Terms'; // Componente de términos
import Privacy from './components/Privacy'; // Componente de privacidad

function App() {
  return (
    <UserProvider>
      <StepperProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Navbar />
            <Suspense fallback={
              <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
              </Box>
            }>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/register" element={<Register />} /> */}
                <Route path="/complete-profile" element={<CompleteProfile />} />
                <Route
                  path="/gift-list/:userId"
                  element={
                    <StepperLayout>
                      <GiftList />
                    </StepperLayout>
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    <StepperLayout>
                      <Checkout />
                    </StepperLayout>
                  }
                />
                <Route
                  path="/thank-you"
                  element={
                    <StepperLayout>
                      <ThankYouPage />
                    </StepperLayout>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <Admin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/invitados"
                  element={
                    <StepperLayout>
                      <Invitados />
                    </StepperLayout>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                {/* Rutas adicionales */}
                <Route path="/faq" element={<FAQ />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                {/* Ruta 404 */}
                <Route path="*" element={<Home />} />
              </Routes>
            </Suspense>
          </Router>
        </ThemeProvider>
      </StepperProvider>
    </UserProvider>
  );
}

export default App;
