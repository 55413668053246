// src/context/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [auth, setAuth] = useState(null); // null: cargando, true: autenticado, false: no autenticado
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        // Verificar la autenticación al montar el componente
        axiosInstance.get('/check-auth')
            .then(response => {
                if (response.data.authenticated && response.data.user) {
                    setAuth(true);
                    setUserInfo(response.data.user);
                } else {
                    setAuth(false);
                }
            })
            .catch(() => {
                setAuth(false);
            });
    }, []);

    const logout = () => {
        axiosInstance.post('/logout')
            .then(() => {
                setAuth(false);
                setUserInfo(null);
            })
            .catch(() => {
                console.error("Error al cerrar sesión.");
            });
    };

    return (
        <UserContext.Provider value={{ auth, userInfo, logout }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser debe ser usado dentro de un UserProvider');
    }
    return context;
}
