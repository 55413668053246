// src/components/Home/Home.js
import React from 'react';
import { Container, Typography, Box, Button, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WeddingImage from '../../assets/wedding.jpg'; // Asegúrate de tener esta imagen
import Feature from './Feature';
import WhyChooseUs from './WhyChooseUs'; // Nueva sección
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import Footer from '../Footer';

function Home() {
    const navigate = useNavigate();

    const handleNoviosClick = () => {
        navigate('/novios-info');
    };

    const handleInvitadosClick = () => {
        navigate('/invitados');
    };

    return (
        <>
            {/* Hero Section */}
            <Box
                sx={{
                    height: '100vh',
                    backgroundImage: `url(${WeddingImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.6)',
                    px: 2,
                }}
            >
                <Fade in timeout={1000}>
                    <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Organiza tus Regalos de Boda con Facilidad
                    </Typography>
                </Fade>
                <Fade in timeout={1500}>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', maxWidth: '700px' }}>
                        Crea tu lista de regalos personalizada, comparte con tus invitados y gestiona contribuciones de manera sencilla, segura y económica.
                    </Typography>
                </Fade>
                <Fade in timeout={2000}>
                    <Box sx={{ mt: 4, display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNoviosClick}
                            sx={{
                                px: 4,
                                py: 1.5,
                                backgroundColor: '#FF8E53',
                                '&:hover': { backgroundColor: '#FF7043' },
                            }}
                        >
                            Crear Lista
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleInvitadosClick}
                            sx={{
                                px: 4,
                                py: 1.5,
                                backgroundColor: '#FF8E53',
                                '&:hover': { backgroundColor: '#FF7043' },
                            }}
                        >
                            Busca una Lista
                        </Button>
                    </Box>
                </Fade>
            </Box>

            {/* Características Destacadas */}
            <Feature />

            {/* Por Qué Elegirnos */}
            <WhyChooseUs />

            {/* Cómo Funciona */}
            <HowItWorks />

            {/* Testimonios */}
            <Testimonials />

            {/* Preguntas Frecuentes */}
            <FAQ />

            {/* Footer */}
            <Footer />
        </>
    );
}

export default Home;
