// src/components/StepperLayout.js
import React, { useContext, useEffect } from 'react';
import { Box, Toolbar } from '@mui/material'; // Importar Toolbar
import CheckoutStepper from './CheckoutStepper';
import { StepperContext } from '../context/StepperContext';
import { useLocation } from 'react-router-dom';

const StepperLayout = ({ children }) => {
    const { setActiveStep } = useContext(StepperContext);
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        if (path.startsWith('/invitados')) {
            setActiveStep(0);
        } else if (path.startsWith('/gift-list')) {
            setActiveStep(1);
        } else if (path.startsWith('/checkout')) {
            setActiveStep(2);
        } else if (path.startsWith('/thank-you')) {
            setActiveStep(3);
        } else {
            setActiveStep(0); // Default al primer paso
        }
    }, [location, setActiveStep]);

    return (
        <Box>
            <Box sx={{ mt: { xs: 2, sm: 4 } }}> {/* Ajustar margen superior adicional si es necesario */}
                <CheckoutStepper />
                {children}
            </Box>
        </Box>
    );
};

export default StepperLayout;
