// src/components/Contact.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Alert, Box, Paper, Grid } from '@mui/material';
import axiosInstance from '../utils/axiosConfig';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? "" : "El nombre es requerido.";
        tempErrors.email = formData.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? "" : "Un email válido es requerido.";
        tempErrors.subject = formData.subject ? "" : "El asunto es requerido.";
        tempErrors.message = formData.message ? "" : "El mensaje es requerido.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            axiosInstance.post('/contact', formData)  // Esto ahora apuntará a '/api/contact' debido al baseURL
                .then(response => {
                    setMessage('Mensaje enviado exitosamente.');
                    setSeverity('success');
                    setFormData({
                        name: '',
                        email: '',
                        subject: '',
                        message: '',
                    });
                })
                .catch(error => {
                    console.error(error);
                    setMessage('Error al enviar el mensaje. Intenta nuevamente.');
                    setSeverity('error');
                });
        } else {
            setMessage('Por favor, corrige los errores en el formulario.');
            setSeverity('error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 8, mb: 8 }}>
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#F4E1D2' }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    Contacto
                </Typography>
                {message && <Alert severity={severity} sx={{ mb: 2 }}>{message}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Asunto"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.subject}
                                helperText={errors.subject}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Mensaje"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                fullWidth
                                multiline
                                rows={4}
                                error={!!errors.message}
                                helperText={errors.message}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Enviar Mensaje
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default Contact;
