import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FF8E53', // Naranja suave, acorde al degradado de los botones
        },
        secondary: {
            main: '#4A4A4A', // Gris oscuro // Rosado suave para complementar el tema romántico
        },
        background: {
            default: '#FFF8F0', // Fondo ligeramente cálido para un look más acogedor
            paper: '#FFF1E6', // Fondo de papel suave y cálido
        },
        text: {
            primary: '#4A4A4A', // Un tono gris oscuro para mejor contraste sin ser tan fuerte
            secondary: '#757575',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 700,
            fontSize: '2.5rem',
            color: '#4A4A4A',
        },
        h4: {
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 600,
            fontSize: '1.75rem',
            color: '#4A4A4A',
        },
        button: {
            textTransform: 'none',
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 50,
                    padding: '10px 30px',
                    background: 'linear-gradient(45deg, #FE6B8B, #FF8E53)',
                    color: 'white',
                    '&:hover': {
                        background: 'linear-gradient(45deg, #FF8E53, #FE6B8B)',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    overflow: 'hidden',
                    backgroundColor: '#FFF1E6',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'rgba(255, 248, 240, 0.8)', // Fondo translúcido suave
                    backdropFilter: 'blur(10px)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                },
            },
        },
    },
});

export default theme;