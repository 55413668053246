// src/components/CompleteProfile.js
import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { Container, TextField, Button, Typography, Alert, Box, Paper, Grid, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';

function CompleteProfile() {
    const { userInfo, logout } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const userId = new URLSearchParams(location.search).get('userId') || userInfo?.id;

    const [bankName, setBankName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [rut, setRut] = useState('');
    const [transactionEmail, setTransactionEmail] = useState('');
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        tempErrors.bankName = bankName ? "" : "El nombre del banco es requerido.";
        tempErrors.accountType = accountType ? "" : "El tipo de cuenta es requerido.";
        tempErrors.accountNumber = accountNumber ? "" : "El número de cuenta es requerido.";
        tempErrors.accountHolder = accountHolder ? "" : "El titular de la cuenta es requerido.";
        tempErrors.rut = rut ? "" : "El RUT es requerido.";
        tempErrors.transactionEmail = (transactionEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(transactionEmail)) ? "" : "Un email válido es requerido.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const data = { bankName, accountType, accountNumber, accountHolder, rut, transactionEmail };
            axiosInstance.post(`/users/${userId}/complete-profile`, data)
                .then(response => {
                    setMessage('Perfil completado exitosamente.');
                    setSeverity('success');
                    // Redirigir a dashboard u otra página
                    navigate('/dashboard');
                })
                .catch(error => {
                    console.error(error);
                    setMessage('Error al completar el perfil.');
                    setSeverity('error');
                });
        } else {
            setMessage('Por favor, corrige los errores en el formulario.');
            setSeverity('error');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#F4E1D2' }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    Completa tu Perfil
                </Typography>
                <Typography variant="body1" align="center" gutterBottom>
                    Para finalizar, por favor proporciona tus detalles bancarios.
                </Typography>
                {message && <Alert severity={severity} sx={{ mb: 2 }}>{message}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre del Banco"
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.bankName}
                                helperText={errors.bankName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Tipo de Cuenta"
                                value={accountType}
                                onChange={(e) => setAccountType(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.accountType}
                                helperText={errors.accountType}
                                placeholder="Ej: Ahorros, Corriente"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Número de Cuenta"
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.accountNumber}
                                helperText={errors.accountNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Titular de la Cuenta"
                                value={accountHolder}
                                onChange={(e) => setAccountHolder(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.accountHolder}
                                helperText={errors.accountHolder}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="RUT"
                                value={rut}
                                onChange={(e) => setRut(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.rut}
                                helperText={errors.rut}
                                placeholder="Ej: 12.345.678-9"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email para Transacciones"
                                value={transactionEmail}
                                onChange={(e) => setTransactionEmail(e.target.value)}
                                required
                                fullWidth
                                error={!!errors.transactionEmail}
                                helperText={errors.transactionEmail}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Completar Perfil
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default CompleteProfile;
