// src/components/Home/Testimonials.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import PersonIcon from '@mui/icons-material/Person';

const testimonials = [
    {
        name: 'Ana López',
        feedback: 'Gracias a esta plataforma, organizar nuestra lista de regalos fue muy sencillo y nuestros invitados apreciaron lo fácil que fue contribuir.',
        avatar: null, // Puedes dejarlo en null si no tienes imágenes
    },
    {
        name: 'Carlos Martínez',
        feedback: 'La seguridad y las tarifas reducidas nos dieron la tranquilidad que necesitábamos para gestionar nuestras contribuciones sin preocupaciones.',
        avatar: null,
    },
    {
        name: 'María González',
        feedback: '¡Excelente servicio! La interfaz es intuitiva, y nuestros invitados encontraron muy fácil contribuir a nuestra lista de regalos.',
        avatar: null,
    },
];

function Testimonials() {
    return (
        <Container sx={{ py: 8 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                Testimonios
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ color: '#555', mb: 6 }}>
                Lo que dicen nuestras parejas felices
            </Typography>
            <Grid container spacing={4}>
                {testimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Fade direction="up" delay={index * 200} triggerOnce>
                            <Card sx={{ height: '100%', padding: 2, boxShadow: 3 }}>
                                <CardContent>
                                    <Avatar
                                        sx={{
                                            width: 56,
                                            height: 56,
                                            margin: '0 auto',
                                            mb: 2,
                                            bgcolor: '#FF8E53',
                                            fontSize: 30,
                                        }}
                                    >
                                        {testimonial.name.charAt(0)}
                                    </Avatar>
                                    <Typography variant="h6" align="center" gutterBottom sx={{ color: '#FF8E53' }}>
                                        {testimonial.name}
                                    </Typography>
                                    <Typography variant="body1" align="center" sx={{ color: '#777' }}>
                                        "{testimonial.feedback}"
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Testimonials;
