// Mejoras en el componente "Invitados" desde el punto de vista UX/UI

import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Alert,
    Avatar,
    ListItemAvatar,
    Fade
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';

function Invitados() {
    const [searchTerm, setSearchTerm] = useState('');
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSearch = async () => {
        if (!searchTerm.trim()) {
            setError('Por favor, ingresa un término de búsqueda.');
            setListaUsuarios([]);
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.get(`/users/search`, { params: { q: searchTerm } });
            if (response.data.status === 'success') {
                setListaUsuarios(response.data.data);
                if (response.data.data.length === 0) {
                    setError('No se encontraron usuarios para el término de búsqueda proporcionado.');
                }
            } else {
                setError('Ocurrió un error durante la búsqueda.');
            }
        } catch (err) {
            console.error('Error al buscar usuarios:', err);
            setError('Error al comunicarse con el servidor. Por favor, intenta de nuevo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectUser = (userId) => {
        navigate(`/gift-list/${userId}`);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 8, textAlign: 'center' }}>
            <Fade in timeout={1000}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#4A4A4A' }}>
                    Encuentra la Lista de Regalos
                </Typography>
            </Fade>
            <Typography variant="body1" sx={{ mt: 2, color: '#777' }}>
                Puedes buscar la lista ingresando el nombre de alguno de los novios o utilizando el ID que ellos te compartieron.
            </Typography>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <TextField
                    label="Nombre del Novio o ID del Usuario"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    sx={{ maxWidth: '400px' }}
                />
                <Button
                    onClick={handleSearch}
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: '120px', padding: '10px', backgroundColor: '#FF8E53', color: '#fff', '&:hover': { backgroundColor: '#FF7043' } }}
                >
                    Buscar
                </Button>
            </Box>
            {loading && <CircularProgress sx={{ mt: 4 }} />}
            {error && <Alert severity="error" sx={{ mt: 4 }}>{error}</Alert>}
            <List sx={{ mt: 4 }}>
                {listaUsuarios.map((usuario) => (
                    <ListItem
                        button
                        key={usuario.id}
                        onClick={() => handleSelectUser(usuario.id)}
                        sx={{
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                backgroundColor: '#FFF1E6',
                                transform: 'scale(1.02)',
                                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
                            },
                            borderRadius: '8px',
                            mb: 1,
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: 2,
                            backgroundColor: '#FFF1E6'
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar src={usuario.picture} alt={usuario.accountHolder} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={usuario.accountHolder}
                            secondary={usuario.email}
                            primaryTypographyProps={{ fontWeight: 'bold', color: '#4A4A4A' }}
                        />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
}

export default Invitados;