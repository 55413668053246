// src/components/Home/Feature.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Box } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SecurityIcon from '@mui/icons-material/Security';
import ShareIcon from '@mui/icons-material/Share';
import PercentIcon from '@mui/icons-material/Percent';
import { CheckCircle } from '@mui/icons-material';

const features = [
    {
        title: 'Listas Personalizadas',
        description: 'Crea listas de regalos adaptadas a tus gustos y necesidades, con opciones ilimitadas para que reflejen tu estilo único.',
        icon: <CardGiftcardIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Seguridad Garantizada',
        description: 'Tus datos están protegidos con las mejores medidas de seguridad, asegurando transacciones seguras y privacidad total.',
        icon: <SecurityIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Fácil Compartir',
        description: 'Comparte tu lista con tus invitados de manera sencilla a través de enlaces personalizados por correo electrónico o redes sociales.',
        icon: <ShareIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Tarifas Competitivas',
        description: 'Disfruta de tarifas reducidas del 5%, la mitad que la mayoría de la competencia, para que aproveches al máximo tus regalos.',
        icon: <PercentIcon sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
    {
        title: 'Contribuciones Simplificadas',
        description: 'Nuestros procesos intuitivos facilitan que tus invitados contribuyan sin complicaciones, mejorando su experiencia.',
        icon: <CheckCircle sx={{ fontSize: 60, color: '#FF8E53' }} />,
    },
];

function Feature() {
    return (
        <Container sx={{ py: 8 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                Características Destacadas
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ color: '#555', mb: 6 }}>
                Descubre las ventajas que ofrecemos para hacer tu experiencia inolvidable y más económica.
            </Typography>
            <Grid container spacing={4}>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Fade triggerOnce>
                            <Card sx={{ height: '100%', textAlign: 'center', padding: 3, boxShadow: 3 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                    {feature.icon}
                                </Box>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom sx={{ color: '#FF8E53' }}>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#777' }}>
                                        {feature.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Feature;
