// src/components/CheckoutStepper.js
import React, { useContext } from 'react';
import { Stepper, Step, StepLabel, Box, Typography, Grow } from '@mui/material';
import { CardGiftcard as GiftIcon, Delete as DeleteIcon, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { StepperContext } from '../context/StepperContext';

const steps = [
    {
        label: 'Buscar la Lista',
        icon: <SearchIcon />,
    },
    {
        label: 'Seleccionar Regalo',
        icon: <GiftIcon />,
    },
    {
        label: 'Realizar Pago',
        icon: <AttachMoneyIcon />,
    },
    {
        label: 'Confirmación',
        icon: <CheckCircleIcon />,
    },
];

const CheckoutStepper = () => {
    const { activeStep } = useContext(StepperContext);
    console.log('Active Step:', activeStep);

    return (
        <Box sx={{ width: '100%', mb: 4 }}>
            <Grow in timeout={500}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                icon={step.icon}
                                aria-label={`Paso ${index + 1}: ${step.label}`}
                                sx={{
                                    '& .MuiStepLabel-iconContainer': {
                                        color: activeStep === index ? 'primary.main' : 'grey.400',
                                    },
                                    '& .MuiStepLabel-label': {
                                        color: activeStep === index ? 'primary.main' : 'text.secondary',
                                    },
                                }}
                            >
                                <Typography variant="caption">{step.label}</Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grow>
        </Box>
    );
};

export default CheckoutStepper;
