// src/components/Privacy.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function Privacy() {
    return (
        <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                Política de Privacidad
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Typography variant="body1" paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam scelerisque aliquam odio et faucibus. Proin nec gravida lacus.
                </Typography>
                <Typography variant="body1" paragraph>
                    Fusce nec velit euismod, fermentum erat ut, pellentesque urna. Suspendisse potenti. Sed auctor libero nec turpis interdum, et dictum massa posuere.
                </Typography>
                {/* Añade más secciones según sea necesario */}
            </Box>
        </Container>
    );
}

export default Privacy;
