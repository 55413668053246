// src/components/ProtectedRoute.js
import { Navigate, useLocation } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useUser } from '../context/UserContext';

function ProtectedRoute({ children }) {
    const { auth } = useUser();
    const location = useLocation();

    if (auth === null) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Box textAlign="center">
                    <CircularProgress color="primary" />
                    <Typography variant="h5" mt={2}>
                        Verificando tu acceso... Por favor, espera.
                    </Typography>
                </Box>
            </Box>
        );
    }

    return auth ? children : <Navigate to={`/login?next=${location.pathname}`} />;
}

export default ProtectedRoute;
