// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
    return (
        <Box sx={{ py: 6, backgroundColor: '#333', color: '#fff' }}>
            <Box sx={{ maxWidth: '1200px', margin: '0 auto', px: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">
                        © {new Date().getFullYear()} Mi lista de sueños. Todos los derechos reservados.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, md: 0 } }}>
                        <Link component={RouterLink} to="/faq" color="inherit" underline="hover">
                            Preguntas Frecuentes
                        </Link>
                        <Link component={RouterLink} to="/contact" color="inherit" underline="hover">
                            Contacto
                        </Link>
                        <Link component={RouterLink} to="/terms" color="inherit" underline="hover">
                            Términos y Condiciones
                        </Link>
                        <Link component={RouterLink} to="/privacy" color="inherit" underline="hover">
                            Política de Privacidad
                        </Link>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <IconButton href="https://facebook.com" target="_blank" rel="noopener" color="inherit">
                        <Facebook />
                    </IconButton>
                    <IconButton href="https://instagram.com" target="_blank" rel="noopener" color="inherit">
                        <Instagram />
                    </IconButton>
                    <IconButton href="https://twitter.com" target="_blank" rel="noopener" color="inherit">
                        <Twitter />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default Footer;
