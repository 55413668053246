import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Card,
    Typography,
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Drawer,
    Badge,
    List,
    ListItem,
    ListItemText,
    Divider,
    Snackbar,
    Alert,
    Tooltip,
    useMediaQuery,
    Fade,
    Chip
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import Skeleton from '@mui/material/Skeleton';
import { NumericFormat } from 'react-number-format';
import { CardGiftcard as GiftIcon, Delete as DeleteIcon, ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { StepperContext } from '../context/StepperContext';

// Estilo para las tarjetas con animación y fondo mejorado
const AnimatedCard = styled(Card)(({ theme }) => ({
    padding: 16,
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF1E6',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    position: 'relative',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
    height: '100%',
}));

// Estilo para la tarjeta del resumen
const SummaryCard = styled(Box)(({ theme }) => ({
    padding: 24,
    borderRadius: 8,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF1E6',
    border: '1px solid #FF8E53',
    position: 'fixed',
    top: 80,
    right: 24,
    width: 300,
    [theme.breakpoints.down('md')]: {
        position: 'static',
        width: '100%',
        marginTop: 24,
    },
}));

// Estilo para el total fijo
const FixedTotalBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 24,
    right: 24,
    backgroundColor: '#FFF1E6', // Fondo claro para alinearse con la estética de la página
    color: '#4A4A4A', // Texto más oscuro para contraste profesional
    padding: '16px 24px',
    borderRadius: '16px', // Bordes suaves y redondeados
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // Sombra sutil para darle algo de profundidad
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    zIndex: 1000,
    width: '250px', // Tamaño fijo para mantener consistencia
    [theme.breakpoints.down('md')]: {
        bottom: 16,
        right: 16,
        padding: '14px 20px',
    },
}));

function GiftList() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const { setActiveStep } = useContext(StepperContext);

    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedGifts, setSelectedGifts] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        setActiveStep(1); // Paso 1: Seleccionar Regalos
        if (userId) {
            // Fetch gifts for the given userId
            axiosInstance.get(`/users/${userId}/gifts`)
                .then(response => {
                    console.log('Datos recibidos:', response.data);
                    setGifts(response.data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            // Si no hay userId, redirigir o mostrar mensaje
            setLoading(false);
            navigate('/');
        }
    }, [userId, setActiveStep, navigate]);

    const handleAddToCart = (gift) => {
        setSelectedGifts((prev) => ({
            ...prev,
            [gift.id]: {
                ...gift,
                quantity: (prev[gift.id]?.quantity || 0) + 1,
            }
        }));
        setSnackbar({ open: true, message: `${gift.name} añadido a la cesta`, severity: 'success' });
    };

    const handleRemoveFromCart = (gift) => {
        setSelectedGifts((prev) => {
            const updatedGifts = { ...prev };
            if (updatedGifts[gift.id]) {
                if (updatedGifts[gift.id].quantity > 1) {
                    updatedGifts[gift.id].quantity -= 1;
                } else {
                    delete updatedGifts[gift.id];
                }
            }
            return updatedGifts;
        });
        setSnackbar({ open: true, message: `${gift.name} eliminado del carrito`, severity: 'info' });
    };

    const handleCheckout = () => {
        navigate('/checkout', { state: { selectedGifts, total: calculateTotal(), userId } });
    };

    const calculateTotal = () => {
        return Object.values(selectedGifts).reduce((total, gift) => total + (gift.price * gift.quantity), 0);
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleRemoveGift = (giftId) => {
        setSelectedGifts((prev) => {
            const updatedGifts = { ...prev };
            delete updatedGifts[giftId];  // Elimina el regalo completo
            return updatedGifts;
        });
        setSnackbar({ open: true, message: 'Regalo eliminado del carrito', severity: 'info' });
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Fade in timeout={1000}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#4A4A4A' }}>
                            Lista de Regalos
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1, color: '#555' }}>
                            Explora los regalos disponibles para la lista de bodas. Selecciona la cantidad que deseas contribuir y agrega tus favoritos al carrito.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                        <IconButton color="primary" onClick={toggleDrawer(true)}>
                            <Badge badgeContent={Object.keys(selectedGifts).length} color="secondary">
                                <GiftIcon fontSize="large" />
                            </Badge>
                        </IconButton>
                    </Box>
                </Box>
            </Fade>

            {loading ? (
                <Grid container spacing={4}>
                    {[...Array(6)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <AnimatedCard>
                                <Skeleton variant="rectangular" height={150} />
                                <Box mt={2}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" width="60%" />
                                    <Skeleton variant="text" width="40%" />
                                </Box>
                            </AnimatedCard>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                gifts.length > 0 ? (
                    <Grid container spacing={4}>
                        {gifts.map((gift) => (
                            <Grid item xs={12} sm={6} md={4} key={gift.id}>
                                <AnimatedCard>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        {gift.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                        {gift.description}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'bold' }}>
                                        Precio:
                                        <NumericFormat
                                            value={gift.price}
                                            displayType={'text'}
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            prefix={'$'}
                                        />
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                        Disponibilidad: {gift.total_quantity} unidades
                                    </Typography>
                                    {gift.image_url && (
                                        <img
                                            src={gift.image_url}
                                            alt={gift.name}
                                            style={{ width: '100%', maxHeight: '150px', objectFit: 'cover', marginTop: '10px', borderRadius: '4px' }}
                                        />
                                    )}
                                    <Box mt={2} display="flex" gap={2} justifyContent="center" alignItems="center">
                                        <Badge badgeContent={selectedGifts[gift.id]?.quantity || 0} color="primary" invisible={!selectedGifts[gift.id]}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAddToCart(gift)}
                                                sx={{ minWidth: '130px', padding: '8px 16px' }}
                                            >
                                                Agregar Regalo
                                            </Button>
                                        </Badge>
                                        {selectedGifts[gift.id] && (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => handleRemoveFromCart(gift)}
                                                    sx={{ minWidth: '130px', padding: '8px 16px' }}
                                                >
                                                    Quitar Regalo
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </AnimatedCard>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography variant="body1" align="center" sx={{ mt: 4, color: '#555' }}>
                        No hay regalos disponibles en este momento. Por favor, revisa más tarde.
                    </Typography>
                )
            )}

            {/* Sidebar Summary */}
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{ sx: { width: isMobile ? '100%' : 350, padding: 2 } }}
            >
                <Fade in timeout={500}>
                    <Box>
                        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#4A4A4A', textAlign: 'center' }}>
                            Resumen de Regalos
                        </Typography>
                        <Divider />
                        <List>
                            {Object.values(selectedGifts).map((gift) => (
                                <ListItem key={gift.id} sx={{ py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <ListItemText
                                        primary={gift.name}
                                        secondary={
                                            <>
                                                <NumericFormat
                                                    value={gift.price}
                                                    displayType={'text'}
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    prefix={'$'}
                                                />{' '}
                                                c/u | Total:
                                                <NumericFormat
                                                    value={gift.price * gift.quantity}
                                                    displayType={'text'}
                                                    thousandSeparator='.'
                                                    decimalSeparator=','
                                                    prefix={'$'}
                                                />
                                            </>
                                        }
                                        primaryTypographyProps={{ fontWeight: 'bold' }}
                                    />
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            type="number"
                                            value={gift.quantity}
                                            onChange={(e) => {
                                                const value = Math.max(1, parseInt(e.target.value) || 1);
                                                setSelectedGifts((prev) => ({
                                                    ...prev,
                                                    [gift.id]: {
                                                        ...gift,
                                                        quantity: value,
                                                    },
                                                }));
                                            }}
                                            inputProps={{ min: 1 }}
                                            sx={{ width: '60px', textAlign: 'center' }}
                                        />
                                        <Tooltip title="Eliminar regalo del carrito">
                                            <IconButton onClick={() => handleRemoveGift(gift.id)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">
                                Total Seleccionado:
                                <NumericFormat
                                    value={calculateTotal()}
                                    displayType={'text'}
                                    thousandSeparator='.'
                                    decimalSeparator=','
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            </Typography>
                            // Estilo para el nuevo botón "Regalar"
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleCheckout}
                                sx={{
                                    mt: 2,
                                    py: 1.5,
                                    fontSize: '16px',
                                    backgroundColor: '#FF8E53', // El color principal de tu tema
                                    color: '#FFF',
                                    borderRadius: '20px', // Bordes más suaves
                                    '&:hover': {
                                        backgroundColor: '#FF7043', // Un tono ligeramente más oscuro para hover
                                    },
                                    boxShadow: 'none', // Eliminar sombras para un diseño más plano
                                }}
                                disabled={Object.keys(selectedGifts).length === 0}
                            >
                                Regalar
                            </Button>

                        </Box>
                    </Box>
                </Fade>
            </Drawer>

            {/* Total Fijo */}
            <FixedTotalBox>
                <GiftIcon />
                <Typography variant="h6">
                    Total: <NumericFormat
                        value={calculateTotal()}
                        displayType={'text'}
                        thousandSeparator='.'
                        decimalSeparator=','
                        prefix={'$'}
                    />
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleCheckout}
                    sx={{ mt: 1, py: 1.5 }}
                    disabled={Object.keys(selectedGifts).length === 0}
                >
                    Regalar
                </Button>
            </FixedTotalBox>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default GiftList;