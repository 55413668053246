import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Box, Button, TextField, Grid, Alert, Fade, Card } from '@mui/material';
import axiosInstance from '../utils/axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepperContext } from '../context/StepperContext';
import { styled } from '@mui/system';


const SummaryCard = styled(Card)(({ theme }) => ({
    padding: 24,
    borderRadius: 8,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF1E6',
    marginTop: 24,
    border: '1px solid #FF8E53',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

function Checkout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setActiveStep } = useContext(StepperContext);
    const { userId } = location.state || {};

    const [personalMessage, setPersonalMessage] = useState('');
    const [gifts, setGifts] = useState([]);
    const [error, setError] = useState('');
    const [khipuPaymentUrl, setKhipuPaymentUrl] = useState(null);  // Nueva URL para el pago

    useEffect(() => {
        setActiveStep(2); // Paso 2: Realizar Pago
        if (location.state?.selectedGifts) {
            const selectedGiftsArray = Object.values(location.state.selectedGifts);
            setGifts(selectedGiftsArray);
        } else {
            navigate('/');
        }
    }, [location.state, setActiveStep, navigate]);

    // Generar la URL de pago con Khipu al finalizar la compra
    const handleFinalizePurchase = () => {
        const totalAmount = gifts.reduce((total, gift) => total + (gift.price * gift.quantity), 0);

        // Datos del pago
        const paymentData = {
            receiver_id: '485328', // Reemplaza con tu Receiver ID
            subject: 'Pago por Regalos de la Lista de Novios',
            amount: totalAmount,
            currency: 'CLP',
            return_url: 'http://localhost:3000/',
            cancel_url: 'http://localhost:3000/',
            notify_url: 'https://heavy-weeks-help.loca.lt/notificacion'
        };

        axiosInstance.post('/khipu/create-payment', paymentData)
            .then(response => {
                const paymentUrl = response.data.payment_url;
                console.log(paymentUrl)
                window.location.href = paymentUrl;
            })
            .catch(error => {
                console.error("Error al crear el pago:", error);
            });

    };


    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Fade in timeout={1000}>
                <Box>
                    <Typography variant="h4" component="h1" align="center" sx={{ fontWeight: 'bold', color: '#4A4A4A', mb: 4 }}>
                        ¡Gracias por tu Contribución!
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ mb: 4, color: '#555' }}>
                        Estamos muy agradecidos por tu contribución. Ahora puedes proceder a realizar el pago usando Khipu.
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 4 }}>
                            {error}
                        </Alert>
                    )}

                    <Grid container spacing={4} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <SummaryCard>
                                <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#4A4A4A' }}>
                                    Resumen de los Regalos
                                </Typography>
                                {gifts.length > 0 ? (
                                    gifts.map((gift, index) => (
                                        <Typography key={index} variant="body1" sx={{ mb: 1, color: '#555' }}>
                                            {gift.quantity} x {gift.name} - Precio: $
                                            {gift.price.toFixed(2)} c/u
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant="body1" sx={{ color: 'red' }}>
                                        No hay regalos seleccionados.
                                    </Typography>
                                )}
                            </SummaryCard>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 4 }}>
                        <TextField
                            label="Mensaje Personalizado para los Novios"
                            multiline
                            rows={4}
                            fullWidth
                            value={personalMessage}
                            onChange={(e) => setPersonalMessage(e.target.value)}
                            sx={{ mb: 4 }}
                            placeholder="Opcional: Deja un mensaje especial para los novios."
                            variant="outlined"
                        />
                    </Box>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleFinalizePurchase}
                        fullWidth
                        sx={{ py: 2 }}
                        disabled={gifts.length === 0}
                    >
                        Proceder con Khipu
                    </Button>
                </Box>
            </Fade>
        </Container>
    );
}

export default Checkout;
