// src/utils/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/api',  // Prefijo para las rutas de la API
    withCredentials: true,
});

// Opcional: Agregar un interceptor para manejar respuestas de error globalmente
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Puedes manejar errores aquí si lo deseas
        return Promise.reject(error);
    }
);

export default axiosInstance;
