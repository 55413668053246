// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { Container, TextField, Button, Typography, Alert, Box, Paper, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

function Profile() {
    const { userInfo } = useUser();
    const navigate = useNavigate();
    const userId = userInfo?.id;

    const [profileData, setProfileData] = useState({
        bankName: '',
        accountType: '',
        accountNumber: '',
        accountHolder: '',
        rut: '',
        transactionEmail: '',
        groomName: '',
        brideName: '',
        weddingDate: '',
        additionalInfo: ''
    });
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (userId) {
            axiosInstance.get(`/users/${userId}/profile`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setProfileData(prev => ({
                            ...prev,
                            ...response.data.data
                        }));
                    }
                })
                .catch(() => {
                    setMessage('Error al obtener los detalles del perfil.');
                    setSeverity('error');
                });
        }
    }, [userId]);

    const validate = () => {
        let tempErrors = {};
        tempErrors.bankName = profileData.bankName ? "" : "El nombre del banco es requerido.";
        tempErrors.accountType = profileData.accountType ? "" : "El tipo de cuenta es requerido.";
        tempErrors.accountNumber = profileData.accountNumber ? "" : "El número de cuenta es requerido.";
        tempErrors.accountHolder = profileData.accountHolder ? "" : "El titular de la cuenta es requerido.";
        tempErrors.rut = profileData.rut ? "" : "El RUT es requerido.";
        tempErrors.transactionEmail = (profileData.transactionEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.transactionEmail)) ? "" : "Un email válido es requerido.";
        tempErrors.groomName = profileData.groomName ? "" : "El nombre del novio es requerido.";
        tempErrors.brideName = profileData.brideName ? "" : "El nombre de la novia es requerido.";
        tempErrors.weddingDate = profileData.weddingDate ? "" : "La fecha del matrimonio es requerida.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const data = {
                bankName: profileData.bankName,
                accountType: profileData.accountType,
                accountNumber: profileData.accountNumber,
                accountHolder: profileData.accountHolder,
                rut: profileData.rut,
                transactionEmail: profileData.transactionEmail,
                groomName: profileData.groomName,
                brideName: profileData.brideName,
                weddingDate: profileData.weddingDate,
                additionalInfo: profileData.additionalInfo
            };
            axiosInstance.put(`/users/${userId}/profile`, data)
                .then(response => {
                    if (response.data.status === 'success') {
                        setMessage('Perfil actualizado exitosamente.');
                        setSeverity('success');
                        // Opcional: Actualizar el contexto de usuario si se requiere
                    }
                })
                .catch(error => {
                    console.error(error);
                    setMessage('Error al actualizar el perfil.');
                    setSeverity('error');
                });
        } else {
            setMessage('Por favor, corrige los errores en el formulario.');
            setSeverity('error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#F4E1D2' }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    Perfil de Usuario
                </Typography>
                {message && <Alert severity={severity} sx={{ mb: 2 }}>{message}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Información Bancaria */}
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Información Bancaria
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre del Banco"
                                name="bankName"
                                value={profileData.bankName}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.bankName}
                                helperText={errors.bankName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Tipo de Cuenta"
                                name="accountType"
                                value={profileData.accountType}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.accountType}
                                helperText={errors.accountType}
                                placeholder="Ej: Ahorros, Corriente"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Número de Cuenta"
                                name="accountNumber"
                                value={profileData.accountNumber}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.accountNumber}
                                helperText={errors.accountNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Titular de la Cuenta"
                                name="accountHolder"
                                value={profileData.accountHolder}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.accountHolder}
                                helperText={errors.accountHolder}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="RUT"
                                name="rut"
                                value={profileData.rut}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.rut}
                                helperText={errors.rut}
                                placeholder="Ej: 12.345.678-9"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email para Transacciones"
                                name="transactionEmail"
                                value={profileData.transactionEmail}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.transactionEmail}
                                helperText={errors.transactionEmail}
                            />
                        </Grid>

                        {/* Información de los Novios */}
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Información de los Novios
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Nombre del Novio"
                                name="groomName"
                                value={profileData.groomName}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.groomName}
                                helperText={errors.groomName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Nombre de la Novia"
                                name="brideName"
                                value={profileData.brideName}
                                onChange={handleChange}
                                required
                                fullWidth
                                error={!!errors.brideName}
                                helperText={errors.brideName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Fecha del Matrimonio"
                                name="weddingDate"
                                type="date"
                                value={profileData.weddingDate}
                                onChange={handleChange}
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.weddingDate}
                                helperText={errors.weddingDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Información Adicional"
                                name="additionalInfo"
                                value={profileData.additionalInfo}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={3}
                                placeholder="Opcional: Puedes agregar detalles como lugar de la ceremonia, horarios, etc."
                            />
                        </Grid>
                    </Grid>
                    <Box mt={3}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Actualizar Perfil
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );

}

export default Profile;
