// src/components/Home/FAQ.js
import React, { useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Fade } from 'react-awesome-reveal';

const faqs = [
    {
        question: '¿Cómo creo una lista de regalos?',
        answer: 'Simplemente regístrate, accede a tu cuenta y selecciona "Crear Lista". Sigue las instrucciones para personalizarla según tus preferencias. Es rápido y fácil.',
    },
    {
        question: '¿Es seguro mi información?',
        answer: 'Sí, utilizamos protocolos de seguridad avanzados para proteger tus datos personales y transacciones, garantizando tu privacidad y tranquilidad.',
    },
    {
        question: '¿Cuáles son las tarifas que cobran?',
        answer: 'Nuestra plataforma solo cobra un 5% por los regalos, lo que representa una tarifa reducida comparada con el 10% que cobran otras plataformas.',
    },
    {
        question: '¿Puedo compartir mi lista con múltiples invitados?',
        answer: 'Claro, puedes compartir tu lista a través de enlaces personalizados por correo electrónico, redes sociales o directamente desde la plataforma de manera rápida y sencilla.',
    },
    {
        question: '¿Cómo gestiono las contribuciones?',
        answer: 'Desde tu panel de control, puedes ver todas las contribuciones realizadas, agradecer a tus invitados y actualizar tu lista en tiempo real de forma eficiente.',
    },
    {
        question: '¿Hay costos ocultos?',
        answer: 'No, nuestra plataforma es completamente transparente. Las tarifas son claras y no hay comisiones ocultas. Solo cobramos el 5% por los regalos recibidos.',
    },
    {
        question: '¿Cómo contacto al soporte técnico?',
        answer: 'Puedes contactar a nuestro equipo de soporte a través del formulario de contacto en la sección "Contacto" o enviando un correo a soporte@tusitio.com. Estamos aquí para ayudarte.',
    },
];

function FAQ() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                Preguntas Frecuentes
            </Typography>
            <Typography variant="h6" align="center" gutterBottom sx={{ color: '#555', mb: 6 }}>
                Resolvemos tus dudas
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} md={8}>
                    {faqs.map((faq, index) => (
                        <Fade key={index} direction="up" delay={index * 100} triggerOnce>
                            <Accordion
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                                sx={{
                                    mb: 2,
                                    '&:before': { display: 'none' },
                                    backgroundColor: '#fff',
                                    boxShadow: 1,
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore sx={{ color: '#FF8E53' }} />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <Typography variant="h6" sx={{ color: '#FF8E53' }}>
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" sx={{ color: '#777' }}>
                                        {faq.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Fade>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
}

export default FAQ;
