// src/components/Admin.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import {
    Container,
    TextField,
    Button,
    Typography,
    Alert,
    Box,
    Paper,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Stack,
    Snackbar,
    Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useUser } from '../context/UserContext';
import { NumericFormat } from 'react-number-format';

function Admin() {
    const { userInfo } = useUser();
    const [gifts, setGifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [editGift, setEditGift] = useState(null);
    const [deleteGift, setDeleteGift] = useState(null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newGift, setNewGift] = useState({
        name: '',
        description: '',
        price: '',
        total_quantity: '',
        image_url: ''
    });
    const [addLoading, setAddLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchGifts();
    }, []);

    const fetchGifts = () => {
        axiosInstance.get('/gifts')
            .then(response => {
                setGifts(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                showSnackbar('Error al obtener los regalos.', 'error');
            });
    };

    const handleEditClick = (gift) => {
        setEditGift(gift);
    };

    const handleDeleteClick = (gift) => {
        setDeleteGift(gift);
    };

    const handleEditSave = () => {
        // Validación básica
        if (!editGift.name || !editGift.description || !editGift.price || !editGift.total_quantity) {
            showSnackbar('Por favor, completa todos los campos requeridos para editar.', 'warning');
            return;
        }

        // Preparar datos para la actualización
        const updatedGift = {
            name: editGift.name,
            description: editGift.description,
            price: parseFloat(editGift.price),
            total_quantity: parseInt(editGift.total_quantity),
            image_url: editGift.image_url // Asegúrate de manejar la actualización de la imagen si es necesario
        };

        axiosInstance.patch(`/gifts/${editGift.id}`, updatedGift)
            .then(response => {
                showSnackbar('Regalo actualizado exitosamente.', 'success');
                setEditGift(null);
                fetchGifts();
            })
            .catch(() => {
                showSnackbar('Error al actualizar el regalo.', 'error');
            });
    };

    const confirmDelete = () => {
        axiosInstance.delete(`/gifts/${deleteGift.id}`)
            .then(response => {
                showSnackbar('Regalo eliminado exitosamente.', 'success');
                setDeleteGift(null);
                fetchGifts();
            })
            .catch(() => {
                showSnackbar('Error al eliminar el regalo.', 'error');
            });
    };

    const handleAddGiftChange = (e) => {
        const { name, value } = e.target;
        setNewGift(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddGiftImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewGift(prev => ({
                    ...prev,
                    image_url: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddGiftSubmit = (e) => {
        e.preventDefault();

        // Validación básica
        if (!newGift.name || !newGift.description || !newGift.price || !newGift.total_quantity) {
            showSnackbar('Por favor, completa todos los campos requeridos.', 'warning');
            return;
        }

        setAddLoading(true);

        const formData = new FormData();
        formData.append('name', newGift.name);
        formData.append('description', newGift.description);
        formData.append('price', parseFloat(newGift.price.replace(/\./g, '').replace(',', '.')));
        formData.append('total_quantity', parseInt(newGift.total_quantity));
        if (newGift.image_url) {
            // Convertir base64 a archivo Blob
            const blob = dataURLtoBlob(newGift.image_url);
            formData.append('image', blob, 'regalo.png');
        }

        axiosInstance.post('/gifts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if (response.status === 201) {
                    showSnackbar('Regalo agregado exitosamente.', 'success');
                    setAddDialogOpen(false);
                    setNewGift({
                        name: '',
                        description: '',
                        price: '',
                        total_quantity: '',
                        image_url: ''
                    });
                    fetchGifts();
                }
            })
            .catch(error => {
                console.error(error);
                showSnackbar('Error al agregar el regalo.', 'error');
            })
            .finally(() => {
                setAddLoading(false);
            });
    };

    // Helper para convertir base64 a Blob
    const dataURLtoBlob = (dataurl) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const showSnackbar = (message, severity) => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({
            ...prev,
            open: false
        }));
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#FF8E53' }}>
                Panel de Administración de la Lista de Regalos
            </Typography>
            {message && <Alert severity={severity} sx={{ mb: 2 }}>{message}</Alert>}

            {/* Botón para agregar nuevo regalo */}
            <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setAddDialogOpen(true)}
                >
                    Agregar Nuevo Regalo
                </Button>
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress color="primary" />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Imagen</TableCell>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Precio</TableCell>
                                <TableCell>Cantidad Disponible</TableCell>
                                <TableCell align="center">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {gifts.map((gift) => (
                                <TableRow key={gift.id}>
                                    <TableCell>
                                        {gift.image_url ? (
                                            <img src={gift.image_url} alt={gift.name} style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px' }} />
                                        ) : 'Sin Imagen'}
                                    </TableCell>
                                    <TableCell>{gift.name}</TableCell>
                                    <TableCell>{gift.description}</TableCell>
                                    <TableCell>
                                        <NumericFormat
                                            value={gift.price}
                                            displayType={'text'}
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            prefix={'$'}
                                        />
                                    </TableCell>
                                    <TableCell>{gift.total_quantity}</TableCell>
                                    <TableCell align="center">
                                        <IconButton color="primary" onClick={() => handleEditClick(gift)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => handleDeleteClick(gift)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Diálogo de Edición */}
            <Dialog open={!!editGift} onClose={() => setEditGift(null)} maxWidth="sm" fullWidth>
                <DialogTitle>Editar Regalo</DialogTitle>
                <DialogContent>
                    {editGift && (
                        <Box component="form" noValidate autoComplete="off">
                            <TextField
                                label="Nombre del Regalo"
                                value={editGift.name}
                                onChange={(e) => setEditGift({ ...editGift, name: e.target.value })}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="Descripción"
                                value={editGift.description}
                                onChange={(e) => setEditGift({ ...editGift, description: e.target.value })}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={3}
                                required
                            />
                            <NumericFormat
                                label="Precio"
                                value={editGift.price}
                                onValueChange={(values) => setEditGift({ ...editGift, price: values.value })}
                                customInput={TextField}
                                fullWidth
                                margin="normal"
                                thousandSeparator='.'
                                decimalSeparator=','
                                prefix={'$'}
                                required
                            />
                            <TextField
                                label="Cantidad Disponible"
                                type="number"
                                value={editGift.total_quantity}
                                onChange={(e) => setEditGift({ ...editGift, total_quantity: parseInt(e.target.value) })}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 0 }}
                                required
                            />
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                    fullWidth
                                    startIcon={<AddIcon />}
                                >
                                    Cambiar Imagen
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onloadend = () => {
                                                    setEditGift(prev => ({
                                                        ...prev,
                                                        image_url: reader.result
                                                    }));
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                </Button>
                                {editGift.image_url && (
                                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                                        <Typography variant="body2">Vista Previa de la Imagen:</Typography>
                                        <img src={editGift.image_url} alt="Vista Previa" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '4px' }} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditGift(null)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleEditSave} color="secondary" variant="contained">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de Confirmación de Eliminación */}
            <Dialog open={!!deleteGift} onClose={() => setDeleteGift(null)}>
                <DialogTitle>Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar "{deleteGift?.name}" de la lista de regalos? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteGift(null)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={confirmDelete} color="secondary" variant="contained">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo para Agregar Nuevo Regalo */}
            <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Agregar Nuevo Regalo</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleAddGiftSubmit} noValidate autoComplete="off">
                        <TextField
                            label="Nombre del Regalo"
                            name="name"
                            value={newGift.name}
                            onChange={handleAddGiftChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Descripción"
                            name="description"
                            value={newGift.description}
                            onChange={handleAddGiftChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            required
                        />
                        <Stack direction="row" spacing={2}>
                            <NumericFormat
                                label="Precio"
                                name="price"
                                value={newGift.price}
                                onValueChange={(values) => setNewGift(prev => ({ ...prev, price: values.value }))}
                                customInput={TextField}
                                fullWidth
                                margin="normal"
                                thousandSeparator='.'
                                decimalSeparator=','
                                prefix={'$'}
                                required
                            />
                            <TextField
                                label="Cantidad Disponible"
                                name="total_quantity"
                                type="number"
                                value={newGift.total_quantity}
                                onChange={handleAddGiftChange}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Stack>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            sx={{ mt: 2 }}
                            fullWidth
                            startIcon={<AddIcon />}
                        >
                            Subir Imagen del Regalo
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleAddGiftImage}
                            />
                        </Button>
                        {newGift.image_url && (
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                                <Typography variant="body2">Vista Previa de la Imagen:</Typography>
                                <img src={newGift.image_url} alt="Vista Previa" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '4px' }} />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleAddGiftSubmit}
                        color="secondary"
                        variant="contained"
                        disabled={addLoading}
                    >
                        {addLoading ? <CircularProgress size={24} /> : 'Agregar'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para Feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={10000} // Aumentado a 10 segundos
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Mover al tope derecho
                sx={{
                    '& .MuiAlert-root': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        padding: '10px 20px',
                        fontSize: '1rem',
                    }
                }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Admin;
