// src/components/Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Box, IconButton, Tooltip, Container, Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUser } from '../context/UserContext';
import useMediaQuery from '@mui/material/useMediaQuery';

function NavigationBar() {
    const { auth, userInfo, logout } = useUser();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const drawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                {auth && (
                    <>
                        <ListItem button component={Link} to="/admin">
                            <ListItemIcon><AdminPanelSettingsIcon /></ListItemIcon>
                            <ListItemText primary="Administrar Lista" />
                        </ListItem>
                        <ListItem button component={Link} to="/profile">
                            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                            <ListItemText primary="Perfil" />
                        </ListItem>
                        <ListItem button onClick={logout}>
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary="Cerrar Sesión" />
                        </ListItem>
                        {userInfo && (
                            <ListItem>
                                <Avatar alt={userInfo.username} src={userInfo.picture} />
                                <ListItemText primary={userInfo.username} sx={{ marginLeft: 2 }} />
                            </ListItem>
                        )}
                    </>
                )}
                {!auth && (
                    <ListItem button component={Link} to="/login">
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary="Iniciar Sesión" />
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <AppBar position="static" sx={{ background: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', color: 'text.primary' }}>
            <Container maxWidth="lg">
                <Toolbar>
                    <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
                        <img src="/logo.png" alt="Wedding Logo" style={{ height: '40px', marginRight: '8px' }} />
                        <Typography variant="h6" component="div">
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <strong>MiListaDeSueños</strong>
                            </Link>
                        </Typography>
                    </Box>

                    {!isMobile && auth && (
                        <>
                            <Button color="primary" component={Link} to="/admin" startIcon={<AdminPanelSettingsIcon />} sx={{ textTransform: 'none' }}>
                                Administrar Lista
                            </Button>
                            <Button color="primary" component={Link} to="/profile" startIcon={<AccountCircleIcon />} sx={{ textTransform: 'none' }}>
                                Perfil
                            </Button>
                            <Button color="primary" onClick={logout} startIcon={<ExitToAppIcon />} sx={{ textTransform: 'none' }}>
                                Cerrar Sesión
                            </Button>
                            {userInfo && (
                                <Tooltip title={userInfo.username}>
                                    <Avatar alt={userInfo.username} src={userInfo.picture} sx={{ ml: 2 }} />
                                </Tooltip>
                            )}
                        </>
                    )}

                    {isMobile && (
                        <>
                            <IconButton edge="start" color="primary" aria-label="menu" onClick={toggleDrawer(true)}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                                {drawerList}
                            </Drawer>
                        </>
                    )}

                    {!isMobile && !auth && (
                        <Button color="primary" component={Link} to="/login" startIcon={<ExitToAppIcon />} sx={{ textTransform: 'none' }}>
                            Iniciar Sesión
                        </Button>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavigationBar;
